import React from 'react';

const IconLoader = () => (
  <svg id="logo" width="80" height="92" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">

    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path d="M28.6548 62V31.4545H40.706C43.0128 31.4545 44.9815 31.8672 46.6122 32.6925C48.2528 33.5078 49.5007 34.6662 50.3558 36.1676C51.2209 37.6591 51.6534 39.4141 51.6534 41.4325C51.6534 43.4609 51.2159 45.206 50.3409 46.6676C49.4659 48.1193 48.1982 49.233 46.5376 50.0085C44.8871 50.7841 42.8885 51.1719 40.5419 51.1719H32.473V45.9815H39.4979C40.7308 45.9815 41.755 45.8125 42.5703 45.4744C43.3857 45.1364 43.9922 44.6293 44.3899 43.9531C44.7976 43.277 45.0014 42.4368 45.0014 41.4325C45.0014 40.4183 44.7976 39.5632 44.3899 38.8672C43.9922 38.1712 43.3807 37.6442 42.5554 37.2862C41.7401 36.9183 40.7109 36.7344 39.468 36.7344H35.1129V62H28.6548ZM45.1506 48.0994L52.7422 62H45.6129L38.1854 48.0994H45.1506Z" fill="currentColor" />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

{/* <svg width="80" height="92" viewBox="0 0 80 92" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M28.6548 62V31.4545H40.706C43.0128 31.4545 44.9815 31.8672 46.6122 32.6925C48.2528 33.5078 49.5007 34.6662 50.3558 36.1676C51.2209 37.6591 51.6534 39.4141 51.6534 41.4325C51.6534 43.4609 51.2159 45.206 50.3409 46.6676C49.4659 48.1193 48.1982 49.233 46.5376 50.0085C44.8871 50.7841 42.8885 51.1719 40.5419 51.1719H32.473V45.9815H39.4979C40.7308 45.9815 41.755 45.8125 42.5703 45.4744C43.3857 45.1364 43.9922 44.6293 44.3899 43.9531C44.7976 43.277 45.0014 42.4368 45.0014 41.4325C45.0014 40.4183 44.7976 39.5632 44.3899 38.8672C43.9922 38.1712 43.3807 37.6442 42.5554 37.2862C41.7401 36.9183 40.7109 36.7344 39.468 36.7344H35.1129V62H28.6548ZM45.1506 48.0994L52.7422 62H45.6129L38.1854 48.0994H45.1506Z" fill="black" />
  <path d="M40 1L1 23V68L40 91L79 69V24L40 1Z" fill="black" stroke="black" />
</svg> */}


export default IconLoader;
